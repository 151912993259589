import React from "react";
import { ProductPage } from "@product-app/components";
import { jsx as ___EmotionJSX } from "@emotion/react";

var IndexPage = function IndexPage(_ref) {
  var host = _ref.host,
      data = _ref.data,
      hasError = _ref.hasError,
      slug = _ref.slug;
  return ___EmotionJSX(ProductPage, {
    host: host,
    productSlug: slug,
    data: data,
    hasError: hasError
  });
};

export var __N_SSP = true;
export default IndexPage;